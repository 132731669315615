<template>
  <v-main :class="$vuetify.theme.dark ? undefined : 'grey lighten-3'" class="cls">
    <v-expand-transition>
    <router-view  />
    </v-expand-transition>
  </v-main>
</template>

<script>
export default {

};
</script>
<style lang="scss" scoped>
// .cls{
//   zoom: 90%
// }
</style>
